import * as React from 'react';

import Heading from './Heading';
import IconLink from '../assets/svgs/icons/icon-link.svg';
import IconCheck from '../assets/svgs/icons/icon-check.svg';
import classNames from 'classnames';

function HeadingCopy({ children, link, ...props }) {
  const [status, setStatus] = React.useState(false);

  async function handleCopy(event) {
    event.preventDefault();
    const text = event.target.href;

    await navigator.clipboard.writeText(text);
    setStatus(true);
    setTimeout(() => setStatus(false), 2000);
  }

  const Icon = status ? IconCheck : IconLink;

  return (
    <div>
      <a
        href={`#${link}`}
        onClick={handleCopy}
        className="relative inline-flex items-center pr-12 group"
        title="Copy link to this section"
      >
        <Heading
          {...props}
          className={classNames(
            'pointer-events-none group-hover:opacity-80 inline-block',
            props.className,
          )}
        >
          {children}
        </Heading>
        <div className="absolute top-1/2 -translate-y-1/2 right-3 text-neon-green hidden group-hover:flex pointer-events-none">
          <Icon className="h-6 w-6" />
          {status && (
            <span className="absolute left-full top-1/2 -translate-y-1/2 text-sm ml-2">
              Copied
            </span>
          )}
        </div>
      </a>
    </div>
  );
}

export default HeadingCopy;
