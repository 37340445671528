import * as React from 'react';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import Seo from '../components/Seo';
import Separator from '../components/Separator';
import Header from '../components/Header';
import Hero from '../components/blocks/Hero';
import TableOfContents from '../components/blocks/TableOfContents';
import Logo from '../components/blocks/Logo';
import Colours from '../components/blocks/Colours';
import Fonts from '../components/blocks/Fonts';
import Templates from '../components/blocks/Templates';
import Visuals from '../components/blocks/Visuals';
import Footer from '../components/Footer';
import Photos from '../components/blocks/Photos';
import ToneOfVoice from '../components/blocks/ToneOfVoice';

function IndexPage() {
  return (
    <>
      <Seo />
      <h1 className="sr-only">Fairown Corporate Visual Identity</h1>
      <SkipNavLink />
      <Header />
      <SkipNavContent>
        <main>
          <Hero />
          <Separator />
          <TableOfContents />
          <Separator />
          <Logo />
          <Separator />
          <Colours />
          <Fonts />
          <Separator />
          <ToneOfVoice />
          <Separator />
          <Templates />
          <Separator />
          <Visuals />
          <Photos />
        </main>
      </SkipNavContent>
      <Footer />
    </>
  );
}

export default IndexPage;
