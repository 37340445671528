import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../Container';
import Button from '../Button';
import { tableOfContents } from '../../constants';
import IconCheck from '../../assets/svgs/icons/icon-check.svg';
import IconRemove from '../../assets/svgs/icons/icon-remove.svg';
import Separator from '../Separator';
import HeadingCopy from '../HeadingCopy';

import FairownLogo01 from '../../assets/svgs/logos/Fairown_logo-01.svg';
import FairownLogo02 from '../../assets/svgs/logos/Fairown_logo-02.svg';
import FairownLogo03 from '../../assets/svgs/logos/Fairown_logo-03.svg';
import FairownLogo04 from '../../assets/svgs/logos/Fairown_logo-04.svg';
import FairownLogo05 from '../../assets/svgs/logos/Fairown_logo-05.svg';
import FairownLogo06 from '../../assets/svgs/logos/Fairown_logo-06.svg';
import FairownLogo07 from '../../assets/svgs/logos/Fairown_logo-07.svg';
import FairownLogo08 from '../../assets/svgs/logos/Fairown_logo-08.svg';
import FairownLogo09 from '../../assets/svgs/logos/Fairown_logo-09.svg';
import FairownLogo10 from '../../assets/svgs/logos/Fairown_logo-10.svg';
import FairownLogo11 from '../../assets/svgs/logos/Fairown_logo-11.svg';
import FairownLogo12 from '../../assets/svgs/logos/Fairown_logo-12.svg';

import PrimaryLogo from '../../assets/svgs/logos/PrimaryLogo.svg';
import PrimaryLogoDarkBlue from '../../assets/svgs/logos/PrimaryLogoDarkBlue.svg';
import PrimaryLogoGreen from '../../assets/svgs/logos/PrimaryLogoGreen.svg';
import TertiaryLogoBlack from '../../assets/svgs/logos/TertiaryLogoBlack.svg';
import TertiaryLogoWhite from '../../assets/svgs/logos/TertiaryLogoWhite.svg';
import AlternativeLogoBlack from '../../assets/svgs/logos/AlternativeLogoBlack.svg';
import AlternativeLogoDarkBlue from '../../assets/svgs/logos/AlternativeLogoDarkBlue.svg';
import AlternativeLogoGreen from '../../assets/svgs/logos/AlternativeLogoGreen.svg';
import AlternativeLogoPrimary from '../../assets/svgs/logos/AlternativeLogoPrimary.svg';
import AlternativeLogoWhite from '../../assets/svgs/logos/AlternativeLogoWhite.svg';
import IconsBlack from '../../assets/svgs/logos/IconsBlack.svg';
import IconsDarkBlue from '../../assets/svgs/logos/IconsDarkBlue.svg';
import IconsGreen from '../../assets/svgs/logos/IconsGreen.svg';
import IconsPrimary from '../../assets/svgs/logos/IconsPrimary.svg';
import IconsWhite from '../../assets/svgs/logos/IconsWhite.svg';

function LogoPrimary({ alternative }) {
  return (
    <figure className="border border-gray-border text-center">
      {alternative ? (
        <AlternativeLogoPrimary className="max-h-36 w-auto mx-auto" />
      ) : (
        <PrimaryLogo className="max-h-36 w-auto mx-auto" />
      )}
    </figure>
  );
}

function LogoPrimaryGreen({ alternative }) {
  return (
    <figure className="border border-gray-border bg-primary text-center">
      {alternative ? (
        <AlternativeLogoGreen className="max-h-36 w-auto mx-auto" />
      ) : (
        <PrimaryLogoGreen className="max-h-36 w-auto mx-auto" />
      )}
    </figure>
  );
}

function LogoPrimaryDarkBlue({ alternative }) {
  return (
    <figure className="border border-gray-border bg-neon-green text-center">
      {alternative ? (
        <AlternativeLogoDarkBlue className="max-h-36 w-auto mx-auto" />
      ) : (
        <PrimaryLogoDarkBlue className="max-h-36 w-auto mx-auto" />
      )}
    </figure>
  );
}

function LogoTertiaryWhite({ alternative }) {
  return (
    <figure className="border border-gray-border bg-black text-center">
      {alternative ? (
        <AlternativeLogoWhite className="max-h-36 w-auto mx-auto" />
      ) : (
        <TertiaryLogoWhite className="max-h-36 w-auto mx-auto" />
      )}
    </figure>
  );
}

function LogoTertiaryBlack({ alternative }) {
  return (
    <figure className="border border-gray-border text-center">
      {alternative ? (
        <AlternativeLogoBlack className="max-h-36 w-auto mx-auto" />
      ) : (
        <TertiaryLogoBlack className="max-h-36 w-auto mx-auto" />
      )}
    </figure>
  );
}

function RightVersion() {
  return (
    <div className="text-neon-green flex items-center">
      <IconCheck className="h-6 w-6" />
      <p className="ml-3">Right version</p>
    </div>
  );
}

function WrongVersion() {
  return (
    <div className="text-error flex items-center">
      <IconRemove className="h-6 w-6" />
      <p className="ml-3">Don't Use</p>
    </div>
  );
}

function Logo() {
  return (
    <Container
      tag="section"
      id={kebabCase(tableOfContents.LOGO.MAIN)}
      className="py-12 md:py-32 space-y-12 md:space-y-20"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-40">
        <div className="space-y-14">
          <HeadingCopy link={kebabCase(tableOfContents.LOGO.MAIN)} level="h2">
            {tableOfContents.LOGO.MAIN}
          </HeadingCopy>

          <div className="space-y-6">
            <p>
              Our logo is the focal point of Fairown - a distinctive and clear
              symbol of our brand. It is made of two parts - the icon and the
              wordmark.
            </p>
            <p>
              We respect our logo whenever we use it. If you're using Fairown’s
              logo, always follow the guidelines below to make sure it looks the
              best.
            </p>
          </div>

          <div className='space-y-4'>
            <p className="font-semibold">Guidelines for using our logo:</p>
            <ul className="list-disc pl-6 space-y-4">
              <li>primary logo on white and light backgrounds;</li>
              <li>secondary logo on colourful backgrounds, preferrably on Fairown's colour palette (dark blue and teal);</li>
              <li>tertiary logo on monochrome or multicoloured backgrounds when use of colours is not possible.</li>
            </ul>
          </div>

        </div>

        <div className="pt-8 sm:pt-0 xl:pt-8 space-y-8 md:space-y-14">
          <figure className="border border-gray-border">
            <FairownLogo01 />
          </figure>
          
          <Button href="/assets/logos/Fairown-Primary-Logo.zip">
            Download Primary Logo
          </Button>
        </div>
      </div>

      <Separator />

      <div className="space-y-8 md:space-y-14">
        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <FairownLogo02 />
          <FairownLogo03 />
        </div>

        <Button href="/assets/logos/Fairown-Secondary-Logo.zip">
          Download Secondary Logo
        </Button>
      </div>

      <div className="space-y-8 md:space-y-14">
        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <FairownLogo04 />
          <figure className="border border-gray-border">
            <FairownLogo05 />
          </figure>
        </div>

        <Button href="/assets/logos/Fairown-Tertiary-Logo.zip">
          Download Tertiary Logo
        </Button>
      </div>

      <Separator />

      <div className="space-y-12">
        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <FairownLogo02 />
            <RightVersion />
          </div>
          <div className="space-y-5">
              <FairownLogo06 className="max-h-36 w-auto mx-auto" />
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <figure className="border border-gray-border">
              <FairownLogo01 />
            </figure>
            <RightVersion />
          </div>
          <div className="space-y-5">
            <FairownLogo07 className="max-h-36 w-auto mx-auto" />
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <FairownLogo03 />
            <RightVersion />
          </div>
          <div className="space-y-5">
            <FairownLogo08 className="max-h-36 w-auto mx-auto" />
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <FairownLogo09 />
            <RightVersion />
          </div>
          <div className="space-y-5">
            <FairownLogo10 className="max-h-36 w-auto mx-auto" />
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <FairownLogo11 />
            <RightVersion />
          </div>
          <div className="space-y-5">
            <FairownLogo12 className="max-h-36 w-auto mx-auto" />
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
              <figure>
                <StaticImage
                  src="../../assets/images/Fairown_logo-13.png"
                  alt="Fairown logo"
                />
              </figure>
            <RightVersion />
          </div>
          <div className="space-y-5">
            <figure>
                <StaticImage
                  src="../../assets/images/Fairown_logo-14.png"
                  alt="Fairown logo"
                />
              </figure>
            <WrongVersion />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 sm:gap-8">
          <div className="space-y-5">
            <figure>
                <StaticImage
                  src="../../assets/images/Fairown_logo-15.png"
                  alt="Fairown logo"
                />
              </figure>
            <RightVersion />
          </div>
          <div className="space-y-5">
            <figure>
                <StaticImage
                  src="../../assets/images/Fairown_logo-16.png"
                  alt="Fairown logo"
                />
              </figure>
            <WrongVersion />
          </div>
        </div>

      </div>

      <Separator />

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.LOGO.children.ALTERNATIVE_LOGO)}
      >
        <div className="space-y-14">
          <HeadingCopy
            link={kebabCase(tableOfContents.LOGO.children.ALTERNATIVE_LOGO)}
            level="h3"
          >
            {tableOfContents.LOGO.children.ALTERNATIVE_LOGO}
          </HeadingCopy>

          <p className="md:w-2/3">
            You can use the alternative logo version in special cases. When
            using this version, follow the same colour logic as described in the
            Logo section.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-4 md:gap-8">
          <LogoPrimary alternative />
          <div />
          <LogoPrimaryGreen alternative />
          <LogoPrimaryDarkBlue alternative />
          <LogoTertiaryWhite alternative />
          <LogoTertiaryBlack alternative />
        </div>

        <Button href="/assets/logos/Fairown-Alternative-Logo.zip">
          Download Alternative Logo
        </Button>
      </div>

      <Separator />

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.LOGO.children.ICONS)}
      >
        <HeadingCopy
          link={kebabCase(tableOfContents.LOGO.children.ICONS)}
          level="h3"
        >
          {tableOfContents.LOGO.children.ICONS}
        </HeadingCopy>

        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-4 md:gap-8">
          <figure className="border border-gray-border">
            <IconsPrimary />
          </figure>
          <figure className="border border-gray-border bg-primary">
            <IconsGreen />
          </figure>
          <figure className="border border-gray-border bg-neon-green">
            <IconsDarkBlue />
          </figure>
          <figure className="border border-gray-border bg-black">
            <IconsWhite />
          </figure>
          <figure className="border border-gray-border">
            <IconsBlack />
          </figure>
        </div>

        <Button href="/assets/logos/Fairown-Icons.zip">Download Icons</Button>
      </div>
    </Container>
  );
}

export default Logo;