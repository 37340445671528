import * as React from 'react';
import kebabCase from 'lodash/kebabCase';

import { tableOfContents } from '../../constants';
import Container from '../Container';
import Heading from '../Heading';

function TableOfContents() {
  return (
    <Container tag="section" className="py-12 md:py-32 space-y-14">
      <Heading level="h2" visualLevel="h1">
        Table of Contents
      </Heading>
      <ul className="space-y-6 sm:columns-2 md:columns-3">
        {Object.entries(tableOfContents).map(([key, value]) => (
          <li key={key} className="space-y-5 break-inside-avoid">
            <a
              href={`#${kebabCase(value.MAIN)}`}
              className="font-semibold text-xl hover:text-neon-green hover:underline hover:underline-offset-2"
            >
              {value.MAIN}
            </a>
            {value.children && (
              <ul className="list-disc pl-6 space-y-4">
                {Object.entries(value.children).map(([key, value]) => (
                  <li key={key}>
                    <a
                      href={`#${kebabCase(value)}`}
                      className="hover:text-neon-green hover:underline hover:underline-offset-2"
                    >
                      {value}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default TableOfContents;
