import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../Container';
import { tableOfContents } from '../../constants';
import Button from '../Button';
import Separator from '../Separator';
import HeadingCopy from '../HeadingCopy';

function Templates() {
  return (
    <section id={kebabCase(tableOfContents.TEMPLATES.MAIN)}>
      <Container className="py-12 md:py-32 space-y-12 md:space-y-20">
        <HeadingCopy
          link={kebabCase(tableOfContents.TEMPLATES.MAIN)}
          level="h2"
        >
          {tableOfContents.TEMPLATES.MAIN}
        </HeadingCopy>
        <HeadingCopy
          level="h3"
          id={kebabCase(tableOfContents.TEMPLATES.children.PRESENTATION)}
          link={kebabCase(tableOfContents.TEMPLATES.children.PRESENTATION)}
        >
          {tableOfContents.TEMPLATES.children.PRESENTATION}
        </HeadingCopy>

        <div className="space-y-12">
          <figure>
            <StaticImage
              src="../../assets/images/presentation.png"
              alt="Presentation"
            />
          </figure>

          <Button href="/assets/templates/Fairown-Presentation.zip">
            Download Presentation
          </Button>
        </div>
      </Container>

      <div
        className="bg-purple text-white py-12 md:py-32"
        id={kebabCase(tableOfContents.TEMPLATES.children.LETTERHEAD)}
      >
        <Container className="space-y-12 md:space-y-20">
          <HeadingCopy
            link={kebabCase(tableOfContents.TEMPLATES.children.LETTERHEAD)}
            level="h3"
          >
            {tableOfContents.TEMPLATES.children.LETTERHEAD}
          </HeadingCopy>

          <div className="space-y-12">
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 md:gap-8">
              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Letterhead-A.jpg"
                  alt="Letterhead A"
                />
              </figure>

              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Letterhead-B.jpg"
                  alt="Letterhead B"
                />
              </figure>
            </div>

            <Button href="/assets/templates/Fairown-Letterhead.zip">
              Download Letterhead
            </Button>
          </div>
        </Container>
      </div>

      <div
        className="bg-light-blue py-12 md:py-32"
        id={kebabCase(tableOfContents.TEMPLATES.children.BUSINESS_CARD)}
      >
        <Container className="space-y-12 md:space-y-20">
          <HeadingCopy
            link={kebabCase(tableOfContents.TEMPLATES.children.BUSINESS_CARD)}
            level="h3"
          >
            {tableOfContents.TEMPLATES.children.BUSINESS_CARD}
          </HeadingCopy>

          <div className='space-y-4'>
            <p className="font-semibold">Print information:</p>
            <ul className="list-disc pl-6 space-y-4">
              <li>dimensions: 85 x 55 mm;</li>
              <li>digital print;</li>
              <li>paper: Nautilus Classic 300 g, uncoated.</li>
            </ul>
          </div>

          <div className="space-y-12">
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 md:gap-8">
              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Business-Card-B.jpg"
                  alt="Business Card B"
                />
              </figure>
              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Business-Card-A.jpg"
                  alt="Business Card A"
                />
              </figure>
            </div>

            <Button href="/assets/templates/Fairown-Business-Card.zip">
              Download Business Card
            </Button>
          </div>
        </Container>
      </div>

      <Container className="py-12 md:py-32 space-y-12 md:space-y-32">
        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.TEMPLATES.children.EMAIL_SIGNATURE)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.TEMPLATES.children.EMAIL_SIGNATURE)}
            level="h3"
          >
            {tableOfContents.TEMPLATES.children.EMAIL_SIGNATURE}
          </HeadingCopy>

          <div className='space-y-4'>
            <p class="font-semibold">How to set up the e-mail signature?</p>
            <ul className="list-decimal pl-6 space-y-4">
              <li>Download the signature HTML file.</li>
              <li>Open the file in your browser.</li>
              <li>Select all the elements and copy them.</li>
              <li>Paste the elements to your e-mail client and replace the data with yours.</li>
            </ul>
          </div>

          <div className="grid grid-col-1 sm:grid-cols-2 gap-4 md:gap-8">
            <div className="space-y-12">
              <figure className="space-y-4">
                <StaticImage
                  src="../../assets/images/Fairown-Email-Signature-1.jpg"
                  alt="Business card front"
                />
                <p>Use this signature in daily e-mail communication.</p>
              </figure>
              <Button
                href="/assets/templates/signature/Fairown-Signature-1.htm"
                download
              >
                Download E-mail Signature
              </Button>
            </div>


            <div className="space-y-12">
              <figure className="space-y-4">
                <StaticImage
                  src="../../assets/images/Fairown-Email-Signature-2.jpg"
                  alt="Business card back"
                />
                <p>
                  Use this signature in daily e-mail communication with a
                  potential customer or partner if we don't have an NDA or
                  cooperation agreement with them and your e-mail contains
                  confidential information.
                </p>
              </figure>
              <Button
                href="/assets/templates/signature/Fairown-Signature-2.htm"
                download
              >
                Download E-mail Signature
              </Button>
            </div>
          </div>
        </div>

        <Separator />

        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.TEMPLATES.children.DOCUMENT)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.TEMPLATES.children.DOCUMENT)}
            level="h3"
          >
            {tableOfContents.TEMPLATES.children.DOCUMENT}
          </HeadingCopy>

          <div className="space-y-12">
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 md:gap-8">
              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Document-A.jpg"
                  alt="Document A"
                />
              </figure>

              <figure>
                <StaticImage
                  src="../../assets/images/Fairown-Document-B.jpg"
                  alt="Document B"
                />
              </figure>
            </div>

            <Button href="/assets/templates/Fairown-Document.zip">
              Download Document
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Templates;