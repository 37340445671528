import * as React from 'react';
import kebabCase from 'lodash/kebabCase';

import Container from '../Container';
import { tableOfContents } from '../../constants';
import HeadingCopy from '../HeadingCopy';

function ToneOfVoice() {
  return (
    <Container
      tag="section"
      className="py-12 md:py-32 space-y-12 md:space-y-20"
      id={kebabCase(tableOfContents.TONE_OF_VOICE.MAIN)}
    >
      <HeadingCopy
        link={kebabCase(tableOfContents.TONE_OF_VOICE.MAIN)}
        level="h2"
      >
        {tableOfContents.TONE_OF_VOICE.MAIN}
      </HeadingCopy>

      <div className="bg-gray-background border-t-8 border-purple text-h5 md:text-h3 p-4 md:p-16 space-y-4">
        <p>
          Our tone of voice is friendly, open, rather informal. We prefer the
          first person (“I and we” instead of “it and they”) format when
          communicating to stakeholders - our employees, customers, partners,
          and the public.
        </p>
        <p>We use British English in our communication.</p>
      </div>
    </Container>
  );
}

export default ToneOfVoice;
