import * as React from 'react';

import LogoSVG from '../assets/svgs/logos/logo-primary.svg';
import Container from './Container';

function Header() {
  return (
    <header className="relative z-[1] py-8 md:py-12">
      <Container>
        <a href="/">
          <span className="sr-only">Fairown</span>
          <LogoSVG className="h-6 w-auto" />
        </a>
      </Container>
    </header>
  );
}

export default Header;
