import * as React from 'react';
import { useState, useEffect } from 'react';
import kebabCase from 'lodash/kebabCase';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../Container';
import { CLOUD_URL, tableOfContents } from '../../constants';
import Button from '../Button';
import Separator from '../Separator';
import HeadingCopy from '../HeadingCopy';

function Photos() {
  const data = useStaticQuery(graphql`
    query PhotosQuery {
      photos: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativePath: { regex: "/photos/i" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      team: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativePath: { regex: "/team/i" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

    const allPhotos = data.photos.edges
    const [listPhotos, setListPhotos] = useState([...allPhotos.slice(0, 6)])
    const [loadMorePhotos, setLoadMorePhotos] = useState(false)
    const [hasMorePhotos, setHasMorePhotos] = useState(allPhotos.length > 6)

    const handleLoadMorePhotos = () => {
      setLoadMorePhotos(true)
    }

    useEffect(() => {
      if (loadMorePhotos && hasMorePhotos) {
        const isMorePhotos = listPhotos.length < allPhotos.length
        const nextPhotos = isMorePhotos
          ? allPhotos.slice(listPhotos.length, allPhotos.length)
          : []
        setListPhotos([...listPhotos, ...nextPhotos])
        setLoadMorePhotos(false)
      }
    }, [loadMorePhotos, hasMorePhotos]) //eslint-disable-line
  
    useEffect(() => {
      const isMorePhotos = listPhotos.length < allPhotos.length
      setHasMorePhotos(isMorePhotos)
    }, [listPhotos]) //eslint-disable-line

    const allTeam = data.team.edges
    const [listTeam, setListTeam] = useState([...allTeam.slice(0, 6)])
    const [loadMoreTeam, setLoadMoreTeam] = useState(false)
    const [hasMoreTeam, setHasMoreTeam] = useState(allTeam.length > 6)

    const handleLoadMoreTeam = () => {
      setLoadMoreTeam(true)
    }

    useEffect(() => {
      if (loadMoreTeam && hasMoreTeam) {
        const isMoreTeam = listTeam.length < allTeam.length
        const nextTeam = isMoreTeam
          ? allTeam.slice(listTeam.length, allTeam.length)
          : []
        setListTeam([...listTeam, ...nextTeam])
        setLoadMoreTeam(false)
      }
    }, [loadMoreTeam, hasMoreTeam]) //eslint-disable-line
  
    useEffect(() => {
      const isMoreTeam = listTeam.length < allTeam.length
      setHasMoreTeam(isMoreTeam)
    }, [listTeam]) //eslint-disable-line

  return (
    <Container
      tag="section"
      id={kebabCase(tableOfContents.PHOTOS.MAIN)}
      className="py-12 md:py-32 space-y-12 md:space-y-20"
    >
      <HeadingCopy link={kebabCase(tableOfContents.PHOTOS.MAIN)} level="h2">
        Photos
      </HeadingCopy>

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.PHOTOS.children.MOODBOARD)}
      >
        <HeadingCopy
          link={kebabCase(tableOfContents.PHOTOS.children.MOODBOARD)}
          level="h3"
        >
          {tableOfContents.PHOTOS.children.MOODBOARD}
        </HeadingCopy>

        <div className="space-y-4">
          <p>
            We use photos instead of animated characters in our branding. The
            style of photos is emotional, easy-going, warm, and caring as
            opposed to corporate. You can also use filters when presenting
            photos.
          </p>
          <p className="font-semibold">
            When choosing your photos, make sure they tick the following boxes:
          </p>
          <ul className="space-y-4 list-disc pl-6">
            <li>do the people in this photo look natural and unposed?</li>
            <li>does the picture show emotion?</li>
            <li>does the lighting look natural?</li>
          </ul>
        </div>
      </div>

      <Separator />

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.PHOTOS.children.FILTERS)}
      >
        <HeadingCopy
          link={kebabCase(tableOfContents.PHOTOS.children.FILTERS)}
          level="h3"
        >
          {tableOfContents.PHOTOS.children.FILTERS}
        </HeadingCopy>

        <div className="space-y-12">
          <div className="space-y-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 xl:gap-6">
              <div className="col-span-1 lg:col-span-2">
                <figure className="h-full">
                  <StaticImage
                    src="../../assets/images/photos/01.jpg"
                    alt="Filter example"
                    className="h-full w-full min-h-[13rem]"
                  />
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/photos/01.jpg"
                    alt="Filter example 1"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-primary bg-opacity-50 text-white">
                    <p className="text-sm">Solid Opacity</p>
                    <p className="text-h4">#262556</p>
                    <p className="text-sm text-purple">50%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/photos/01.jpg"
                    alt="Filter example 2"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-primary to-transparent text-white">
                    <p className="text-sm">Linear Gradient</p>
                    <p className="text-h4">#262556</p>
                    <p className="text-sm text-purple">100% &#8594; 0%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1 lg:col-span-2 order-first lg:order-none">
                <div className="bg-primary p-8 text-white h-full flex items-center">
                  <p className="text-h4">
                    Here is another example of using colour filters when
                    presenting pictures
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 xl:gap-6">
              <div className="col-span-1 lg:col-span-2">
                <div className="bg-neon-green p-8 text-white h-full flex items-center">
                  <p className="text-h4">
                    And another filter
                    <br /> you can use.
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/photos/02.jpg"
                    alt="Filter example 1"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-neon-green bg-opacity-50 text-white">
                    <p className="text-sm">Solid Opacity</p>
                    <p className="text-h4">#B58CFF</p>
                    <p className="text-sm text-primary">50%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/photos/02.jpg"
                    alt="Filter example 2"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-neon-green to-transparent text-white">
                    <p className="text-sm">Linear Gradient</p>
                    <p className="text-h4">#B58CFF</p>
                    <p className="text-sm text-primary">100% &#8594; 0%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1 lg:col-span-2">
                <figure className="h-full">
                  <StaticImage
                    src="../../assets/images/photos/02.jpg"
                    alt="Filter example"
                    className="h-full w-full min-h-[13rem]"
                  />
                </figure>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 xl:gap-6">
              <div className="col-span-1 lg:col-span-2">
                <figure className="h-full">
                  <StaticImage
                    src="../../assets/images/filter-3.jpg"
                    alt="Filter example"
                    className="h-full w-full min-h-[13rem]"
                  />
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/filter-3.jpg"
                    alt="Filter example 1"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-purple bg-opacity-50 text-white">
                    <p className="text-sm">Solid Opacity</p>
                    <p className="text-h4">#AF8CFF</p>
                    <p className="text-sm text-primary">50%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/filter-3.jpg"
                    alt="Filter example 2"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-purple to-transparent text-white">
                    <p className="text-sm">Linear Gradient</p>
                    <p className="text-h4">#AF8CFF</p>
                    <p className="text-sm text-primary">100% &#8594; 0%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1 lg:col-span-2 order-first lg:order-none">
                <div className="bg-purple p-8 text-white h-full flex items-center">
                  <p className="text-h4">
                    Additional option to use purple colour
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 xl:gap-6">
              <div className="col-span-1 lg:col-span-2">
                <div className="bg-light-blue p-8 text-primary h-full flex items-center">
                  <p className="text-h4">
                    And another filter
                    <br /> you can use.
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/filter-4.jpg"
                    alt="Filter example 1"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-light-blue bg-opacity-50 text-primary">
                    <p className="text-sm">Solid Opacity</p>
                    <p className="text-h4">#E2EDF3</p>
                    <p className="text-sm text-primary">50%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1">
                <figure className="relative h-full">
                  <StaticImage
                    src="../../assets/images/filter-4.jpg"
                    alt="Filter example 2"
                    className="h-full w-full min-h-[13rem]"
                  />
                  <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-light-blue to-transparent text-primary">
                    <p className="text-sm">Linear Gradient</p>
                    <p className="text-h4">#E2EDF3</p>
                    <p className="text-sm text-primary">100% &#8594; 0%</p>
                  </div>
                </figure>
              </div>
              <div className="col-span-1 lg:col-span-2">
                <figure className="h-full">
                  <StaticImage
                    src="../../assets/images/filter-4.jpg"
                    alt="Filter example"
                    className="h-full w-full min-h-[13rem]"
                  />
                </figure>
              </div>
            </div>

          <Button href="/assets/photos/Fairown-Filters.zip">Download Filters</Button>
        </div>
      </div>

      <Separator />

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.PHOTOS.children.PHOTOS)}
      >
        <HeadingCopy
          link={kebabCase(tableOfContents.PHOTOS.children.PHOTOS)}
          level="h3"
        >
          {tableOfContents.PHOTOS.children.PHOTOS}
        </HeadingCopy>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 gap-y-8 md:gap-y-16">
          {listPhotos.map(({ node }) => {
            const image = getImage(node.childImageSharp);
            return (
              <div className="space-y-6" key={node.id}>
                <figure
                  key={node.id}
                  className="aspect-[16/11] overflow-hidden"
                >
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    className="object-cover w-full h-full"
                  />
                </figure>

                <Button
                  href={`${CLOUD_URL}/photos/${node.name}.png`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </Button>
              </div>
            );
          })}
        </div>
        {hasMorePhotos &&
        <div className="md:space-y-12">
            <Button onClick={handleLoadMorePhotos} variant="tertiary" className="px-14">Show all</Button>
        </div>
        }
      </div>

      <Separator />

      <div
        className="space-y-12 md:space-y-20"
        id={kebabCase(tableOfContents.PHOTOS.children.TEAM)}
      >
        <HeadingCopy
          link={kebabCase(tableOfContents.PHOTOS.children.TEAM)}
          level="h3"
        >
          {tableOfContents.PHOTOS.children.TEAM}
        </HeadingCopy>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 gap-y-8 md:gap-y-16">
          {listTeam.map(({ node }) => {
            const image = getImage(node.childImageSharp);
            return (
              <div className="space-y-6" key={node.id}>
                <figure
                  key={node.id}
                  className="aspect-[16/11] overflow-hidden"
                >
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    className="object-cover w-full h-full"
                  />
                </figure>

                <Button href={`${CLOUD_URL}/photos/team/${node.name}.zip`}>
                  Download
                </Button>
              </div>
            );
          })}
        </div>
        {hasMoreTeam &&
        <div className="md:space-y-12">
            <Button onClick={handleLoadMoreTeam} variant="tertiary" className="px-14">Show all</Button>
        </div>
        }
      </div>
    </Container>
  );
}

export default Photos;