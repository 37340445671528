import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import classNames from 'classnames';

import Container from '../Container';
import Heading from '../Heading';
import { tableOfContents } from '../../constants';
import HeadingCopy from '../HeadingCopy';

const colors = {
  purple: {
    title: 'Purple',
    background: 'bg-purple',
    text: 'text-white',
    hex: '#B58CFF',
    rgb: 'RGB - 181 140 255',
    cmyk: 'CMYK - 40 52 0 0',
    pantone: 'PANTONE - 528',
  },
  navyBlue: {
    title: 'Navy Blue',
    background: 'bg-navy-blue',
    text: 'text-white',
    hex: '#262556',
    rgb: 'RGB - 38 37 86',
    cmyk: 'CMYK - 95 88 12 45',
    pantone: 'PANTONE - 662',
  },
  neonGreen: {
    title: 'Neon Green',
    background: 'bg-neon-green',
    text: 'text-primary',
    hex: '#75D5D2',
    rgb: 'RGB - 117 213 210',
    cmyk: 'CMYK - 64 0 31 0',
    pantone: 'PANTONE - 3242',
  },
  lightBlue: {
    title: 'Light Blue',
    background: 'bg-light-blue',
    text: 'text-primary',
    hex: '#E2EDF3',
    rgb: 'RGB - 226 237 243',
    cmyk: 'CMYK - 15  2  4  0',
    pantone: 'PANTONE - 656',
  },
};

function Colours() {
  return (
    <Container
      tag="section"
      id={kebabCase(tableOfContents.COLORS.MAIN)}
      className="py-12 md:py-32 space-y-12 md:space-y-20"
    >
      <HeadingCopy link={kebabCase(tableOfContents.COLORS.MAIN)} level="h2">
        {tableOfContents.COLORS.MAIN}
      </HeadingCopy>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 xl:gap-8">
        {Object.values(colors).map((color, index) => (
          <div
            key={index}
            className={classNames(
              color.background,
              color.text,
              'p-4 md:p-5 space-y-32',
            )}
          >
            <Heading level="h3" weight="normal">
              {color.title}
            </Heading>

            <ul>
              <li>{color.hex}</li>
              <li>{color.rgb}</li>
              <li>{color.cmyk}</li>
              <li>{color.pantone}</li>
            </ul>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Colours;
