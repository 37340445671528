import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../Container';
import Heading from '../Heading';
import HeroElementSVG from '../../assets/svgs/elements/hero-element.svg';

function Hero() {
  return (
    <Container tag="section" className="md:pt-32 md:-mt-32 md:pb-32">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
        <div className="space-y-14 pt-8 sm:pt-0 xl:pt-8">
          <Heading>
            Corporate
            <br /> Visual Identity
          </Heading>

          <div className="space-y-6">
            <p>We are all Fairown’s brand ambassadors.</p>
            <p>
              Our brand is much more than a logo on a website. We represent our
              brand in our communication, photos, graphics, and visuals. These
              elements help us explain what we stand for and when we all use
              them, we create a unified and strong brand. Strong brands are key
              in customer and partner relations, better recognised and trusted
              by the public.
            </p>
            <p>
              This site gives you the tools you need to help build Fairown’s
              brand. This way we know what it should look like and have clear
              guidelines to make sure we get it right.
            </p>
          </div>
        </div>

        <div className="relative md:after:absolute after:-top-[100vh] after:-bottom-32 after:left-1/2 after:-z-10 after:w-screen after:bg-light-blue">
          <figure className="-mx-4 md:mx-0">
            <StaticImage
              src="../../assets/images/Fairown.jpg"
              alt="Fairown Corporate Visual Identity"
            />
          </figure>
          <HeroElementSVG className="absolute top-8 right-0 xl:-right-5" />
        </div>
      </div>
    </Container>
  );
}

export default Hero;
