import * as React from 'react';
import classNames from 'classnames';

function Heading({
  children,
  level = 'h1',
  visualLevel,
  tag,
  weight = 'semibold',
  position,
  uppercase,
  lineHeight,
  whiteSpace,
  className,
  ...props
}) {
  const Tag = tag || level;
  const visual = visualLevel || level;

  return (
    <Tag
      className={classNames(
        {
          'text-bold sm:text-bold': visual === 'bold',
          'text-semibold sm:text-semibold': visual === 'semibold',
          'text-h2 sm:text-h1': visual === 'h1',
          'text-h3 sm:text-h2': visual === 'h2',
          'text-h3': visual === 'h3',
          'text-h4': visual === 'h4',
          'text-h5': visual === 'h5',
          'text-h6': visual === 'h6',
          'font-light': weight === 'light',
          'font-normal': weight === 'normal',
          'font-semibold': weight === 'semibold',
          'font-bold': weight === 'bold',
          'text-left justify-start': position === 'left',
          'text-center justify-center': position === 'center',
          'text-right justify-end': position === 'right',
          uppercase,
          'leading-none': lineHeight === '1',
          'whitespace-nowrap': whiteSpace === 'nowrap',
        },
        className,
      )}
      {...props}
    >
      <span>{children}</span>
    </Tag>
  );
}

export default Heading;
