import * as React from 'react';

import LogoSVG from '../assets/svgs/logos/logo-primary.svg';
import FooterElementSVG from '../assets/svgs/elements/footer-element.svg';
import Container from './Container';

function Footer() {
  return (
    <footer className="py-8 md:py-12 border-t border-t-gray-border">
      <Container className="flex justify-between items-center">
        <div className="space-y-4">
          <a href="/">
            <span className="sr-only">Fairown</span>
            <LogoSVG className="h-6 w-auto" />
          </a>
          <p className="text-sm font-semibold text-gray-text">
            Enabling a Circular Economy
          </p>
        </div>

        <FooterElementSVG />
      </Container>
    </footer>
  );
}

export default Footer;
