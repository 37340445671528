import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import LoadingSVG from '../assets/svgs/loading.svg';

function Button({
  children,
  type = 'button',
  variant = 'primary',
  loading,
  disabled,
  className,
  to,
  ...props
}) {
  const classNamesMap = classNames(
    'inline-flex items-center justify-center text-center font-semibold text-base py-4 px-6 rounded-full border w-full sm:w-auto focus:outline-none',
    className,
    {
      'border-transparent bg-primary hover:bg-primary/80 active:bg-primary/80 text-white focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary':
        variant === 'primary',
      'bg-transparent border-neon-green hover:border-neon-green/80 active:border-neon-green/80 text-white focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary':
        variant === 'secondary',
      'border-transparent bg-purple hover:bg-purple/80 active:bg-purple/80 text-white focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary':
        variant === 'tertiary',
      'bg-transparent border-transparent text-black hover:opacity-80 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-current':
        variant === 'ghost',
      'cursor-default border-transparent opacity-80': disabled,
    },
  );

  const Tag = to ? Link : props.href ? 'a' : 'button';
  const linkProps = {
    ...(to && {
      href: to,
    }),
  };
  const buttonProps = {
    ...(!to && {
      type,
    }),
    ...(props.href && {
      type: null,
    }),
  };

  return (
    <Tag
      className={classNamesMap}
      disabled={disabled}
      {...props}
      {...linkProps}
      {...buttonProps}
    >
      {loading && <LoadingSVG className="h-6 w-6 animate-spin" />}
      <span className={classNames({ 'ml-2': loading })}>{children}</span>
    </Tag>
  );
}

export default Button;
