export const CLOUD_URL = 'https://storage.googleapis.com/fairown-cvi-assets/release-v14';

export const tableOfContents = {
  LOGO: {
    MAIN: 'Logo',
    children: {
      ALTERNATIVE_LOGO: 'Alternative Logo',
      ICONS: 'Icons',
    },
  },
  COLORS: {
    MAIN: 'Colours',
  },
  FONTS: {
    MAIN: 'Fonts',
    children: {
      MARKETING_MATERIALS: 'Marketing Materials and Web',
      CORPORATE: 'Corporate Documents',
      PRESENTATIONS: 'Presentations',
    },
  },
  TONE_OF_VOICE: {
    MAIN: 'Tone of Voice',
  },
  TEMPLATES: {
    MAIN: 'Templates',
    children: {
      PRESENTATION: 'Presentation',
      LETTERHEAD: 'Letterhead',
      BUSINESS_CARD: 'Business Card',
      EMAIL_SIGNATURE: 'E-mail Signature',
      DOCUMENT: 'Document',
    },
  },
  VISUALS: {
    MAIN: 'Visuals',
    children: {
      SOCIAL_MEDIA_COVERS: 'LinkedIn Covers',
      SOCIAL_MEDIA_TEMPLATES: 'Social Media Templates',
      ICON_SET: 'Icon Set',
      ELEMENTS: 'Elements',
    },
  },
  PHOTOS: {
    MAIN: 'Photos',
    children: {
      MOODBOARD: 'Moodboard',
      PHOTOS: 'Selection of Photos',
      FILTERS: 'Filters',
      TEAM: 'Team',
    },
  },
};
