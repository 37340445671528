import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../Container';
import Heading from '../Heading';
import { tableOfContents } from '../../constants';
import Button from '../Button';

import Graphic12SVG from '../../assets/svgs/visuals/graphic-12.svg';
import Separator from '../Separator';
import HeadingCopy from '../HeadingCopy';

function Visuals() {
  const data = useStaticQuery(graphql`
    query VisualsQuery {
      socialMediaCovers: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          name: { regex: "/Fairown-LinkedIn-cover/i" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      socialMediaTemplates: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          name: { regex: "/social-media-template/i" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      iconsSolid: allFile(
        filter: {
          sourceInstanceName: { eq: "svgs" }
          relativePath: { regex: "/icons-solid/i" }
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
      iconsOutline: allFile(
        filter: {
          sourceInstanceName: { eq: "svgs" }
          relativePath: { regex: "/icons-outline/i" }
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `);

  return (
    <section id={kebabCase(tableOfContents.VISUALS.MAIN)}>
      <Container className="py-12 md:py-32 space-y-12 md:space-y-20">
        <HeadingCopy link={kebabCase(tableOfContents.VISUALS.MAIN)} level="h2">
          {tableOfContents.VISUALS.MAIN}
        </HeadingCopy>
        <HeadingCopy
          level="h3"
          id={kebabCase(tableOfContents.VISUALS.children.SOCIAL_MEDIA_COVERS)}
          link={kebabCase(tableOfContents.VISUALS.children.SOCIAL_MEDIA_COVERS)}
        >
          {tableOfContents.VISUALS.children.SOCIAL_MEDIA_COVERS}
        </HeadingCopy>

        <div className="space-y-12">
          <div className="space-y-4 md:space-y-8">
            {data.socialMediaCovers.edges.map(({ node }) => {
              const image = getImage(node.childImageSharp);
              return (
                <figure key={node.id}>
                  <GatsbyImage image={image} alt={node.name} />
                </figure>
              );
            })}
          </div>

          <Button href="/assets/visuals/Fairown-LinkedIn-Covers.zip">
            Download LinkedIn Covers
          </Button>
        </div>
      </Container>

      <div
        className="bg-gray-background py-12 md:py-32"
        id={kebabCase(tableOfContents.VISUALS.children.SOCIAL_MEDIA_TEMPLATES)}
      >
        <Container className="space-y-12 md:space-y-20">
          <HeadingCopy
            link={kebabCase(
              tableOfContents.VISUALS.children.SOCIAL_MEDIA_TEMPLATES,
            )}
            level="h3"
          >
            {tableOfContents.VISUALS.children.SOCIAL_MEDIA_TEMPLATES}
          </HeadingCopy>

          <div className="space-y-12">
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 md:gap-8">
              {data.socialMediaTemplates.edges.map(({ node }) => {
                const image = getImage(node.childImageSharp);
                return (
                  <figure key={node.id}>
                    <GatsbyImage image={image} alt={node.name} />
                  </figure>
                );
              })}
            </div>

            <Button href="/assets/visuals/Fairown-Social-Media-Templates.zip">
              Download Social Media Templates
            </Button>
          </div>
        </Container>
      </div>

      <Container className="py-12 md:pt-32 md:pb-20 space-y-12 md:space-y-32">
        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.VISUALS.children.ICON_SET)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.VISUALS.children.ICON_SET)}
            level="h3"
          >
            {tableOfContents.VISUALS.children.ICON_SET}
          </HeadingCopy>

          <div className="grid grid-col-1 sm:grid-cols-2 gap-8 md:gap-32">
            <div className="space-y-12">
              <Heading level="h4" visualLevel="h5" weight="normal">
                Outline icons
              </Heading>
              <ul className="grid grid-cols-4 md:grid-cols-6 gap-4">
                {data.iconsOutline.edges.map(({ node }) => (
                  <li key={node.id}>
                    <figure>
                      <img
                        src={node.publicURL}
                        alt={`${node.name} icon`}
                        loading="lazy"
                        height="60"
                        width="60"
                      />
                    </figure>
                  </li>
                ))}
              </ul>
              <Button href="/assets/visuals/Fairown-Icons-Outline.zip">
                Download Outline Icons
              </Button>
            </div>

            <div className="space-y-12">
              <Heading level="h4" visualLevel="h5" weight="normal">
                Solid icons
              </Heading>
              <ul className="grid grid-cols-4 md:grid-cols-6 gap-4">
                {data.iconsSolid.edges.map(({ node }) => (
                  <li key={node.id}>
                    <figure>
                      <img
                        src={node.publicURL}
                        alt={`${node.name} icon`}
                        loading="lazy"
                        height="60"
                        width="60"
                      />
                    </figure>
                  </li>
                ))}
              </ul>
              <Button href="/assets/visuals/Fairown-Icons-Solid.zip">
                Download Solid Icons
              </Button>
            </div>
          </div>
        </div>
      </Container>

      <div
        className="py-12 md:py-24"
        id={kebabCase(tableOfContents.VISUALS.children.ELEMENTS)}
      >
        <Container className="space-y-12 md:space-y-20">
          <HeadingCopy
            link={kebabCase(tableOfContents.VISUALS.children.ELEMENTS)}
            level="h3"
          >
            {tableOfContents.VISUALS.children.ELEMENTS}
          </HeadingCopy>

          <div className="grid grid-col-1 sm:grid-cols-2 gap-8 md:gap-32">
            
            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-1.png" alt="Graphic 1" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-1.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-2.png" alt="Graphic 2" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-2.zip">
                Download Graphics
              </Button>
            </div>

          </div>
        </Container>
      </div>

      <div className="bg-light-blue py-12 md:py-24">
        <Container className="space-y-12">
          <div className="grid grid-col-1 sm:grid-cols-2 gap-8 md:gap-32">

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-3.png" alt="Graphic 3" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-3.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-4.png" alt="Graphic 4" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-4.zip">
                Download Graphics
              </Button>
            </div>

          </div>
        </Container>
      </div>

      <div className="py-12 md:py-24">
        <Container className="space-y-12 md:space-y-20">
          <div className="grid grid-col-1 sm:grid-cols-2 gap-8 md:gap-32">
            
            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-5.png" alt="Graphic 5" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-5.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-6.png" alt="Graphic 6" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-6.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-7.png" alt="Graphic 7" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-7.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-8.png" alt="Graphic 8" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-8.zip">
                Download Graphics
              </Button>
            </div>

          </div>
        </Container>
      </div>

      <Container className="space-y-12 md:space-y-20">

      <Separator />

        <div className="space-y-12 md:space-y-20 text-center">
          <figure className="md:w-3/5 mx-auto">
            <StaticImage src="../../assets/images/graphic-11.png" alt="Graphic 11" />
          </figure>
          <Button href="/assets/visuals/graphics/Fairown-Graphic-11.zip">
            Download Graphics
          </Button>
        </div>

        <Separator />

        <div className="space-y-12 md:space-y-20 text-center">
          <figure className="md:w-3/4 mx-auto">
            <Graphic12SVG />
          </figure>
          <Button href="/assets/visuals/graphics/Fairown-Graphic-12.zip">
            Download Graphics
          </Button>
        </div>

        <Separator />

      </Container>

      <div className="py-12 md:py-12">
        <Container className="space-y-12">
          <div className="grid grid-col-1 sm:grid-cols-2 gap-8 md:gap-32">

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-9.png" alt="Graphic 9" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-9.zip">
                Download Graphics
              </Button>
            </div>

            <div className="space-y-8">
              <figure className="aspect-square flex items-center">
                <StaticImage src="../../assets/images/graphic-10.png" alt="Graphic 10" />
              </figure>
              <Button href="/assets/visuals/graphics/Fairown-Graphic-10.zip">
                Download Graphics
              </Button>
            </div>

          </div>
        </Container>
      </div>

    </section>

  );
}

export default Visuals;