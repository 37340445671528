import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import classNames from 'classnames';

import Container from '../Container';
import Heading from '../Heading';
import FontsElementSVG from '../../assets/svgs/elements/fonts-element.svg';
import Button from '../Button';
import { tableOfContents } from '../../constants';
import Separator from '../Separator';
import HeadingCopy from '../HeadingCopy';

const euclid = [
  {
    title: 'Headline',
    header: 'Euclid Circular A Bold',
    suffix: 'Font Size 50pt',
    fontSize: 'text-bold',
    fontWeight: 'font-bold',
  },
  {
    header: 'Euclid Circular A SemiBold',
    suffix: 'Font Size 50pt',
    fontSize: 'text-semibold',
    fontWeight: 'font-semibold',
  },
  {
    title: 'Body Text',
    header: 'Euclid Circular A Regular',
    suffix: 'Font Size 20pt',
    fontSize: 'text-regular',
    fontWeight: 'font-normal',
  },
  {
    header: 'Euclid Circular A Light',
    suffix: 'Font Size 20pt',
    fontSize: 'text-light',
    fontWeight: 'font-light',
  },
];

const arial = [
  {
    title: 'Headline:',
    header: 'Arial Bold',
    suffix: 'Font Size 20 pt',
    fontSize: 'text-xl',
    fontWeight: 'font-bold',
  },
  {
    title: 'Body text:',
    header: 'Arial Regular',
    suffix: 'Font Size 12 pt',
    fontSize: 'text-lg',
    fontWeight: 'font-normal',
  },
];

const poppins = [
  {
    title: 'Headline 1:',
    header: 'Poppins Bold',
    suffix: 'Font Size 44 pt',
    fontSize: 'text-44',
    fontWeight: 'font-bold',
  },
  {
    title: 'Headline 2:',
    header: 'Poppins Bold',
    suffix: 'Font Size 28 pt',
    fontSize: 'text-28',
    fontWeight: 'font-bold',
  },
  {
    title: 'Body text:',
    header: 'Poppins Regular',
    suffix: 'Font Size 20 pt',
    fontSize: 'text-20',
    fontWeight: 'font-20',
  },
];

function Fonts() {
  return (
    <section id={kebabCase(tableOfContents.FONTS.MAIN)}>
      <div className="bg-gray-background py-12 md:py-32">
        <Container className="relative">
          <div className="space-y-12 md:space-y-20">
            <HeadingCopy
              link={kebabCase(tableOfContents.FONTS.MAIN)}
              level="h2"
            >
              {tableOfContents.FONTS.MAIN}
            </HeadingCopy>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              <div className="space-y-4 md:space-y-14">
                <div className="space-y-2">
                  <p className="text-purple text-2xl">
                    Marketing Materials and Web
                  </p>
                  <Heading level="h3">Euclid Circular A</Heading>
                </div>

                <Button href="/assets/fonts/Fairown-Euclid.zip">Download Font</Button>
              </div>

              <div className="space-y-4 md:space-y-14">
                <div className="space-y-2">
                  <p className="text-neon-green text-2xl">
                    Corporate Documents
                  </p>
                  <Heading level="h3" className="font-arial">
                    Arial
                  </Heading>
                </div>

                <Button href="/assets/fonts/Fairown-Arial.zip">Download Font</Button>
              </div>

              <div className="space-y-4 md:space-y-14">
                <div className="space-y-2">
                  <p className="text-neon-green text-2xl">Presentation</p>
                  <Heading level="h3" className="font-poppins">
                    Poppins
                  </Heading>
                </div>

                <Button href="/assets/fonts/Fairown-Poppins.zip">Download Font</Button>
              </div>
            </div>
          </div>

          <FontsElementSVG className="absolute -top-12 -left-24" />
        </Container>
      </div>

      <Container className="py-12 md:py-32 space-y-12 md:space-y-32">
        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.FONTS.children.MARKETING_MATERIALS)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.FONTS.children.MARKETING_MATERIALS)}
            level="h3"
          >
            {tableOfContents.FONTS.children.MARKETING_MATERIALS}
          </HeadingCopy>

          <div className="space-y-12">
            <p className="text-purple text-h4 font-semibold">Euclid Font</p>

            <ul className="space-y-8">
              {euclid.map((font, index) => (
                <li
                  key={index}
                  className={classNames(font.fontWeight, 'lg:w-3/4 space-y-2')}
                >
                  <p className="text-gray-text">{font.title}</p>
                  <div className="flex flex-col md:flex-row justify-between md:items-center space-y-2 md:space-y-0">
                    <p className={classNames(font.fontSize)}>{font.header}</p>
                  </div>
                </li>
              ))}
            </ul>

            <Button href="/assets/fonts/Fairown-Euclid.zip">Download Font</Button>
          </div>
        </div>

        <Separator />

        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.FONTS.children.CORPORATE)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.FONTS.children.CORPORATE)}
            level="h3"
          >
            {tableOfContents.FONTS.children.CORPORATE}
          </HeadingCopy>

          <div className="space-y-12">
            <p className="text-neon-green text-h4 font-bold font-arial">Arial Font</p>

            <ul className="space-y-8">
              {arial.map((font, index) => (
                <li
                  key={index}
                  className={classNames(font.fontWeight, 'md:w-1/3 space-y-2')}
                >
                  <p className="text-gray-text">{font.title}</p>
                  <div className="flex justify-between items-center">
                    <p className={classNames('font-arial', font.fontSize)}>
                      {font.header}
                    </p>
                    <p>{font.suffix}</p>
                  </div>
                </li>
              ))}
            </ul>

            <Button href="/assets/fonts/Fairown-Arial.zip">Download Font</Button>
          </div>
        </div>

        <Separator />

        <div
          className="space-y-12 md:space-y-20"
          id={kebabCase(tableOfContents.FONTS.children.PRESENTATIONS)}
        >
          <HeadingCopy
            link={kebabCase(tableOfContents.FONTS.children.PRESENTATIONS)}
            level="h3"
          >
            {tableOfContents.FONTS.children.PRESENTATIONS}
          </HeadingCopy>

          <div className="space-y-12">
            <p className="text-neon-green text-h4 font-semibold font-poppins">
              Poppins Font
            </p>

            <ul className="space-y-8">
              {poppins.map((font, index) => (
                <li
                  key={index}
                  className={classNames(font.fontWeight, 'md:w-2/4 space-y-2')}
                >
                  <p className="text-gray-text">{font.title}</p>
                  <div className="flex justify-between items-center">
                    <p className={classNames('font-poppins', font.fontSize)}>
                      {font.header}
                    </p>
                    <p>{font.suffix}</p>
                  </div>
                </li>
              ))}
            </ul>

            <Button href="/assets/fonts/Fairown-Poppins.zip">Download Font</Button>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Fonts;