import * as React from 'react';
import classNames from 'classnames';

function Separator({ className }) {
  return (
    <hr
      className={classNames('w-full border-t border-gray-border', className)}
    />
  );
}

export default Separator;
